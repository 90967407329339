import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_new_device",
  ref: "newDeviceModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered modal-xl mw-1000px rounded" }
const _hoisted_3 = { class: "modal-content roundeda" }
const _hoisted_4 = { class: "modal-body scroll-y px-10 px-lg-15 pt-5 pb-15" }
const _hoisted_5 = { class: "d-flex flex-column mb-8 fv-row" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!
  const _component_DeviceLora = _resolveComponent("DeviceLora")!
  const _component_DeviceSigfox = _resolveComponent("DeviceSigfox")!
  const _component_DeviceNbiot = _resolveComponent("DeviceNbiot")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Modal - New Target"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Modal dialog"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Modal content"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Modal header"),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "modal-header border-0 bg-primary text-white" }, [
            _createCommentVNode("begin::Heading"),
            _createCommentVNode("begin::Title"),
            _createElementVNode("span", {
              class: "modal-title h5 text-uppercase text-white",
              id: "exampleModalLabel",
              style: {"margin-left":"1.1rem"}
            }, " Add Device "),
            _createCommentVNode("end::Title"),
            _createElementVNode("div", {
              class: "btn btn-icon btn-sm ms-5",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ]),
            _createCommentVNode("end::Heading")
          ], -1 /* HOISTED */)),
          _createCommentVNode("begin::Modal header"),
          _createCommentVNode("begin::Modal body"),
          _createElementVNode("div", _hoisted_4, [
            _createCommentVNode("begin:Form"),
            _createVNode(_component_Form, {
              id: "kt_modal_new_target_form",
              onSubmit: _withModifiers(_ctx.submit, ["prevent"]),
              model: _ctx.targetData,
              rules: _ctx.rules,
              ref: "formRef",
              class: "form"
            }, {
              default: _withCtx(() => [
                _createCommentVNode("begin::Input group"),
                _createElementVNode("div", _hoisted_5, [
                  _createCommentVNode("begin::Label"),
                  _cache[2] || (_cache[2] = _createElementVNode("label", { class: "d-flex align-items-center fs-6 fw-bold mb-2" }, [
                    _createElementVNode("span", { class: "" }, "Variant"),
                    _createElementVNode("i", {
                      class: "fas fa-exclamation-circle ms-2 fs-7",
                      "data-bs-toggle": "tooltip",
                      title: "Specify a target priorty"
                    })
                  ], -1 /* HOISTED */)),
                  _createCommentVNode("end::Label"),
                  _createVNode(_component_Field, {
                    name: "select_variant",
                    "data-control": "select2",
                    "data-hide-search": "true",
                    "data-placeholder": "Select a Variant...",
                    class: "form-select form-select-solid border border-primary",
                    as: "select",
                    modelValue: _ctx.targetData.tags,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.targetData.tags) = $event))
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("option", { value: "sigfox" }, "Sigfox", -1 /* HOISTED */),
                      _createElementVNode("option", { value: "lora" }, "Lora", -1 /* HOISTED */),
                      _createElementVNode("option", { value: "nbiot" }, "nbIot", -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _createCommentVNode("end::Input group"),
                _createCommentVNode("begin::Actions"),
                _createCommentVNode("end::Actions")
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onSubmit", "model", "rules"]),
            _createCommentVNode("end:Form"),
            _createCommentVNode(" Lora Device Form "),
            (_ctx.targetData.tags == 'lora')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_DeviceLora, {
                    val: _ctx.targetData.tags
                  }, null, 8 /* PROPS */, ["val"])
                ]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" Lora Device Form end "),
            _createCommentVNode(" Sigfox Device Form "),
            (_ctx.targetData.tags == 'sigfox')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_DeviceSigfox, {
                    val: _ctx.targetData.tags
                  }, null, 8 /* PROPS */, ["val"])
                ]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" Sigfox Device Form end "),
            _createCommentVNode(" NBIOT Device Form "),
            (_ctx.targetData.tags == 'nbiot')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_DeviceNbiot, {
                    val: _ctx.targetData.tags
                  }, null, 8 /* PROPS */, ["val"])
                ]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" NBIOT Device Form end ")
          ]),
          _createCommentVNode("end::Modal body")
        ]),
        _createCommentVNode("end::Modal content")
      ]),
      _createCommentVNode("end::Modal dialog")
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("end::Modal - New Target")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}