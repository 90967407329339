import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { ref: "createDeviceModalRef" }
const _hoisted_2 = { class: "modal-body pb-10 pt-0 px-lg-17" }
const _hoisted_3 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_create_api_key_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_create_api_key_header",
  "data-kt-scroll-wrappers": "#kt_modal_create_api_key_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_4 = { class: "row fs-5" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_8 = { class: "fv-plugins-message-container" }
const _hoisted_9 = { class: "fv-help-block" }
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = { class: "mb-5 fv-row" }
const _hoisted_12 = { class: "col-md-4" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "mb-5 fv-row" }
const _hoisted_15 = {
  key: 1,
  class: "d-flex flex-column mb-5 fv-row"
}
const _hoisted_16 = { class: "fv-plugins-message-container" }
const _hoisted_17 = { class: "fv-help-block" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-md-4" }
const _hoisted_20 = { class: "mb-5 fv-row" }
const _hoisted_21 = { class: "fv-plugins-message-container" }
const _hoisted_22 = { class: "fv-help-block" }
const _hoisted_23 = { class: "col-md-4" }
const _hoisted_24 = {
  key: 0,
  class: "d-flex flex-column mb-5 fv-row"
}
const _hoisted_25 = { class: "fv-plugins-message-container" }
const _hoisted_26 = { class: "fv-help-block" }
const _hoisted_27 = {
  key: 1,
  class: "d-flex flex-column mb-5 fv-row"
}
const _hoisted_28 = { class: "fv-plugins-message-container" }
const _hoisted_29 = { class: "fv-help-block" }
const _hoisted_30 = { class: "col-md-4" }
const _hoisted_31 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_32 = { class: "fv-plugins-message-container" }
const _hoisted_33 = { class: "fv-help-block" }
const _hoisted_34 = { class: "row" }
const _hoisted_35 = { class: "col-md-4" }
const _hoisted_36 = {
  key: 0,
  class: "mb-5 fv-row"
}
const _hoisted_37 = { class: "fv-plugins-message-container" }
const _hoisted_38 = { class: "fv-help-block" }
const _hoisted_39 = { key: 1 }
const _hoisted_40 = { class: "mb-5 fv-row" }
const _hoisted_41 = { class: "fv-plugins-message-container" }
const _hoisted_42 = { class: "fv-help-block" }
const _hoisted_43 = { class: "col-md-4" }
const _hoisted_44 = {
  key: 0,
  class: "d-flex flex-column mb-5 fv-row"
}
const _hoisted_45 = { class: "fv-plugins-message-container" }
const _hoisted_46 = { class: "fv-help-block" }
const _hoisted_47 = { class: "modal-footer flex-center" }
const _hoisted_48 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_create_api_key_submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createCommentVNode("begin::Form"),
    _createVNode(_component_Form, {
      id: "kt_modal_create_api_key_form",
      class: "form",
      onSubmit: _ctx.submit,
      "validation-schema": _ctx.validationSchema
    }, {
      default: _withCtx(() => [
        _createCommentVNode("begin::Modal body"),
        _createElementVNode("div", _hoisted_2, [
          _createCommentVNode("begin::Scroll"),
          _createElementVNode("div", _hoisted_3, [
            _createCommentVNode(" Firsr Row "),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Field, {
                name: "ver",
                "data-control": "select2",
                "data-hide-search": "true",
                "data-placeholder": "Select a Category...",
                class: "form-select form-select-solid border border-primary",
                as: "select",
                modelValue: _ctx.picked,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.picked) = $event))
              }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("option", { value: "v1" }, "V1", -1 /* HOISTED */),
                  _createElementVNode("option", { value: "v2" }, "V2", -1 /* HOISTED */)
                ])),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ]),
            _cache[24] || (_cache[24] = _createElementVNode("hr", { class: "mt-2 mb-15" }, null, -1 /* HOISTED */)),
            _createCommentVNode(" <div v-if=\"picked == 'v1'\">v one is here </div>\r\n          <div v-if=\"picked == 'v2'\">v Two is here </div> "),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createCommentVNode("begin::Input group"),
                _createElementVNode("div", _hoisted_7, [
                  _createCommentVNode("begin::Label"),
                  _cache[13] || (_cache[13] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Application Key", -1 /* HOISTED */)),
                  _createCommentVNode("end::Label"),
                  _createCommentVNode("begin::Input"),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "form-control form-control-solid border border-primary",
                    rows: "3",
                    name: "application_key",
                    placeholder: "Application Key",
                    modelValue: _ctx.loraData.application_key,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loraData.application_key) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_ErrorMessage, { name: "application_key" })
                    ])
                  ]),
                  _createCommentVNode("end::Input")
                ]),
                _createCommentVNode("end::Input group")
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createCommentVNode("begin::Label"),
                  _cache[14] || (_cache[14] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2 ms-2" }, "Product Count", -1 /* HOISTED */)),
                  _createCommentVNode("end::Label"),
                  _createCommentVNode("begin::Input"),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                    placeholder: "Product Count",
                    name: "product_count",
                    modelValue: _ctx.loraData.product_count,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.loraData.product_count) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createCommentVNode("end::Input")
                ]),
                _createCommentVNode("begin::Input group"),
                _createCommentVNode(" <div class=\"d-flex flex-column mb-5 fv-row\">\r\n               \r\n                <label class=\"required fs-5 fw-bold mb-2 ms-2\">Variant Id</label>\r\n              \r\n\r\n             \r\n                <Field type=\"text\" class=\"form-control form-control-solid  border border-primary\" rows=\"3\"\r\n                  name=\"variant_id\" placeholder=\"Enter variant id\" v-model=\"loraData.variant_id\" />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"variant_id\" />\r\n                  </div>\r\n                </div>\r\n           \r\n              </div> "),
                _createCommentVNode("end::Input group")
              ]),
              _createElementVNode("div", _hoisted_12, [
                (_ctx.picked == 'v2')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_14, [
                        _createCommentVNode("begin::Label"),
                        _cache[15] || (_cache[15] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2 ms-2" }, "Product ID", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                          placeholder: "Product Id",
                          name: "product_id",
                          modelValue: _ctx.loraData.product_id,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.loraData.product_id) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode("begin::Input group"),
                (_ctx.picked == 'v1')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createCommentVNode("begin::Label"),
                      _cache[16] || (_cache[16] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Unique Random Number", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Input"),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid border border-primary",
                        rows: "3",
                        name: "unique_random_number",
                        placeholder: "Unique random number",
                        modelValue: _ctx.loraData.unique_random_number,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.loraData.unique_random_number) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createVNode(_component_ErrorMessage, { name: "unique_random_number" })
                        ])
                      ]),
                      _createCommentVNode("end::Input")
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode("end::Input group")
              ])
            ]),
            _createCommentVNode(" First Row end "),
            _createCommentVNode(" Second row "),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createCommentVNode("begin::Input group"),
                _createElementVNode("div", _hoisted_20, [
                  _createCommentVNode("begin::Label"),
                  _cache[17] || (_cache[17] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Application EUI", -1 /* HOISTED */)),
                  _createCommentVNode("end::Label"),
                  _createCommentVNode("begin::Input"),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                    placeholder: "Application EUI",
                    name: "application_eui",
                    modelValue: _ctx.loraData.application_eui,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.loraData.application_eui) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_ErrorMessage, { name: "application_eui" })
                    ])
                  ]),
                  _createCommentVNode("end::Input")
                ]),
                _createCommentVNode("end::Input group")
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createCommentVNode("begin::Input group"),
                (_ctx.picked == 'v1')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _createCommentVNode("begin::Label"),
                      _cache[18] || (_cache[18] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Device Serial Number", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Input"),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid border border-primary",
                        rows: "3",
                        name: "serial_number",
                        placeholder: "Serial number",
                        modelValue: _ctx.loraData.serial_number,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.loraData.serial_number) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", _hoisted_26, [
                          _createVNode(_component_ErrorMessage, { name: "serial_number" })
                        ])
                      ]),
                      _createCommentVNode("end::Input")
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.picked == 'v2')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createCommentVNode("begin::Label"),
                      _cache[19] || (_cache[19] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Device Serial ID", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Input"),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid border border-primary",
                        rows: "3",
                        name: "serial_number",
                        placeholder: "Serial number",
                        modelValue: _ctx.loraData.serial_number,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.loraData.serial_number) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createVNode(_component_ErrorMessage, { name: "serial_number" })
                        ])
                      ]),
                      _createCommentVNode("end::Input")
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode("end::Input group")
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createCommentVNode("begin::Input group"),
                _createElementVNode("div", _hoisted_31, [
                  _createCommentVNode("begin::Label"),
                  _cache[20] || (_cache[20] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Device EUI", -1 /* HOISTED */)),
                  _createCommentVNode("end::Label"),
                  _createCommentVNode("begin::Input"),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "form-control form-control-solid border border-primary",
                    rows: "3",
                    name: "device_eui",
                    placeholder: "Device Eui",
                    modelValue: _ctx.loraData.device_eui,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.loraData.device_eui) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createVNode(_component_ErrorMessage, { name: "device_eui" })
                    ])
                  ]),
                  _createCommentVNode("end::Input")
                ]),
                _createCommentVNode("end::Input group")
              ])
            ]),
            _createCommentVNode(" Second row end "),
            _createCommentVNode(" Third Row "),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, [
                _createCommentVNode("begin::Input group"),
                (_ctx.picked == 'v1')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                      _createCommentVNode("begin::Label"),
                      _cache[21] || (_cache[21] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2 ms-2" }, "Network Session Key", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Input"),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                        placeholder: "Network Session Key",
                        name: "network_session_key",
                        modelValue: _ctx.loraData.network_session_key,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.loraData.network_session_key) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("div", _hoisted_38, [
                          _createVNode(_component_ErrorMessage, { name: "network_session_key" })
                        ])
                      ]),
                      _createCommentVNode("end::Input")
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.picked == 'v2')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                      _createCommentVNode("begin::Input group"),
                      _createElementVNode("div", _hoisted_40, [
                        _createCommentVNode("begin::Label"),
                        _cache[22] || (_cache[22] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Device UID", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Input"),
                        _createVNode(_component_Field, {
                          type: "text",
                          class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                          placeholder: "Device UID",
                          name: "unique_random_number",
                          modelValue: _ctx.loraData.unique_random_number,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.loraData.unique_random_number) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_41, [
                          _createElementVNode("div", _hoisted_42, [
                            _createVNode(_component_ErrorMessage, { name: "unique_random_number" })
                          ])
                        ]),
                        _createCommentVNode("end::Input")
                      ]),
                      _createCommentVNode("end::Input group")
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode("end::Input group")
              ]),
              _createCommentVNode("begin::Input group"),
              _createCommentVNode(" <div class=\"d-flex flex-column mb-5 fv-row\">\r\n              \r\n                <label class=\"fs-5 fw-bold mb-2 ms-2\">Firmware Version</label>\r\n          \r\n\r\n               \r\n                <Field type=\"text\" class=\"form-control form-control-solid  border border-primary\" rows=\"3\"\r\n                  name=\"firmware_version\" placeholder=\"Firmware Version\" v-model=\"loraData.firmware_version\" />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"firmware_version\" />\r\n                  </div>\r\n                </div>\r\n               \r\n              </div> "),
              _createCommentVNode("end::Input group"),
              _createElementVNode("div", _hoisted_43, [
                _createCommentVNode("begin::Input group"),
                (_ctx.picked == 'v1')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                      _createCommentVNode("begin::Label"),
                      _cache[23] || (_cache[23] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2 ms-2" }, "Application Session Key", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Input"),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid border border-primary",
                        name: "application_session_key",
                        placeholder: "Application Session Key",
                        modelValue: _ctx.loraData.application_session_key,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.loraData.application_session_key) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_45, [
                        _createElementVNode("div", _hoisted_46, [
                          _createVNode(_component_ErrorMessage, { name: "application_session_key" })
                        ])
                      ]),
                      _createCommentVNode("end::Input")
                    ]))
                  : _createCommentVNode("v-if", true),
                _createCommentVNode("end::Input group")
              ])
            ]),
            _createCommentVNode(" Third Row end "),
            _createCommentVNode(" Four Row  "),
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "row" }, [
              _createElementVNode("div", { class: "col-md-4" }, [
                _createCommentVNode("begin::Input group"),
                _createCommentVNode("end::Input group")
              ]),
              _createElementVNode("div", { class: "col-md-4" }, [
                _createCommentVNode("begin::Input group"),
                _createCommentVNode(" <div class=\"d-flex flex-column mb-5 fv-row\">\r\n              \r\n                <label class=\"fs-5 fw-bold mb-2\">Sensor Type</label>\r\n              \r\n\r\n                \r\n                <Field name=\"sensor\" data-control=\"select2\" data-hide-search=\"true\" placeholder=\"Sensor type\"\r\n                  data-placeholder=\"Sensor Type\" class=\"form-select border-primary form-select-solid\" as=\"select\"\r\n                  v-model=\"loraData.sensor\">\r\n                  <option value=\"JSN 2.0\">JSN 2.0</option>\r\n                  <option value=\"JSN 3.0\">JSN 3.0</option>\r\n                  <option value=\"TOF\">TOF</option>\r\n                </Field>\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"sensor\" />\r\n                  </div>\r\n                </div> \r\n              </div> "),
                _createCommentVNode("end::Input group")
              ])
            ], -1 /* HOISTED */)),
            _createCommentVNode(" Four Row End ")
          ]),
          _createCommentVNode("end::Scroll")
        ]),
        _createCommentVNode("end::Modal body"),
        _createCommentVNode("begin::Modal footer"),
        _createElementVNode("div", _hoisted_47, [
          _createCommentVNode("begin::Button"),
          _createElementVNode("button", _hoisted_48, _cache[26] || (_cache[26] = [
            _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
            _createElementVNode("span", { class: "indicator-progress" }, [
              _createTextVNode(" Please wait... "),
              _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
            ], -1 /* HOISTED */)
          ]), 512 /* NEED_PATCH */),
          _createCommentVNode("end::Button")
        ]),
        _createCommentVNode("end::Modal footer")
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onSubmit", "validation-schema"]),
    _createCommentVNode("end::Form")
  ], 512 /* NEED_PATCH */))
}