import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modal-body pb-10 pt-0 px-lg-17" }
const _hoisted_2 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_create_api_key_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_create_api_key_header",
  "data-kt-scroll-wrappers": "#kt_modal_create_api_key_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row fs-5" }
const _hoisted_5 = { class: "col-md-4" }
const _hoisted_6 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_7 = { class: "fv-plugins-message-container" }
const _hoisted_8 = { class: "fv-help-block" }
const _hoisted_9 = { class: "col-md-4" }
const _hoisted_10 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_11 = { class: "fv-plugins-message-container" }
const _hoisted_12 = { class: "fv-help-block" }
const _hoisted_13 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_14 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_18 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-md-4" }
const _hoisted_21 = { class: "mb-5 fv-row" }
const _hoisted_22 = {
  key: 0,
  class: "col-md-4"
}
const _hoisted_23 = { class: "mb-5 fv-row" }
const _hoisted_24 = { class: "fv-plugins-message-container" }
const _hoisted_25 = { class: "fv-help-block" }
const _hoisted_26 = {
  key: 1,
  class: "col-md-4"
}
const _hoisted_27 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_28 = { class: "fv-plugins-message-container" }
const _hoisted_29 = { class: "fv-help-block" }
const _hoisted_30 = {
  key: 2,
  class: "col-md-4"
}
const _hoisted_31 = { class: "d-flex flex-column mb-5 fv-row" }
const _hoisted_32 = { class: "fv-plugins-message-container" }
const _hoisted_33 = { class: "fv-help-block" }
const _hoisted_34 = { class: "modal-footer flex-center" }
const _hoisted_35 = {
  ref: "submitButtonRef",
  type: "submit",
  id: "kt_modal_create_api_key_submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createCommentVNode("begin::Form"),
    _createVNode(_component_Form, {
      id: "kt_modal_create_api_key_form",
      class: "form",
      onSubmit: _ctx.submit,
      "validation-schema": _ctx.validationSchema
    }, {
      default: _withCtx(() => [
        _createCommentVNode("begin::Modal body"),
        _createElementVNode("div", _hoisted_1, [
          _createCommentVNode("begin::Scroll"),
          _createElementVNode("div", _hoisted_2, [
            _createCommentVNode(" Firsr Row "),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Field, {
                  name: "customer_type",
                  "data-control": "select2",
                  "data-hide-search": "true",
                  "data-placeholder": "Select a Category...",
                  class: "form-select form-select-solid border border-primary",
                  as: "select",
                  modelValue: _ctx.picked,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.picked) = $event))
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("option", { value: "v1" }, "V1", -1 /* HOISTED */),
                    _createElementVNode("option", { value: "v2" }, "V2", -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _cache[14] || (_cache[14] = _createElementVNode("hr", { class: "mt-2 mb-15" }, null, -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_5, [
                _createCommentVNode("begin::Input group"),
                _createElementVNode("div", _hoisted_6, [
                  _createCommentVNode("begin::Label"),
                  _cache[10] || (_cache[10] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "ICCID", -1 /* HOISTED */)),
                  _createCommentVNode("end::Label"),
                  _createCommentVNode("begin::Input"),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "form-control form-control-solid border border-primary",
                    rows: "3",
                    name: "icc",
                    placeholder: "ICCID",
                    modelValue: _ctx.nbiotData.icc,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nbiotData.icc) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_ErrorMessage, { name: "icc" })
                    ])
                  ]),
                  _createCommentVNode("end::Input")
                ]),
                _createCommentVNode("end::Input group")
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createCommentVNode("begin::Input group"),
                _createElementVNode("div", _hoisted_10, [
                  _createCommentVNode("begin::Label"),
                  _cache[11] || (_cache[11] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "IMEI", -1 /* HOISTED */)),
                  _createCommentVNode("end::Label"),
                  _createCommentVNode("begin::Input"),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "form-control form-control-solid border border-primary",
                    rows: "3",
                    name: "variant_id",
                    placeholder: "Enter Imei",
                    modelValue: _ctx.nbiotData.variant_id,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.nbiotData.variant_id) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_ErrorMessage, { name: "variant_id" })
                    ])
                  ]),
                  _createCommentVNode("end::Input")
                ]),
                _createCommentVNode("end::Input group")
              ]),
              (_ctx.picked == 'v1')
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createCommentVNode("begin::Input group"),
                    _createElementVNode("div", _hoisted_14, [
                      _createCommentVNode("begin::Label"),
                      _cache[12] || (_cache[12] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Unique Random Number", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Input"),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid border border-primary",
                        rows: "3",
                        name: "unique_random_number",
                        placeholder: "Describe your API",
                        modelValue: _ctx.nbiotData.unique_random_number,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.nbiotData.unique_random_number) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_ErrorMessage, { name: "unique_random_number" })
                        ])
                      ]),
                      _createCommentVNode("end::Input")
                    ]),
                    _createCommentVNode("end::Input group")
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.picked == 'v2')
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _cache[13] || (_cache[13] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2 ms-2" }, "Product ID", -1 /* HOISTED */)),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid border border-primary",
                        rows: "3",
                        name: "product_id",
                        placeholder: "Product ID",
                        modelValue: _ctx.nbiotData.product_id,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.nbiotData.product_id) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _createCommentVNode(" First Row end "),
            _createCommentVNode(" Second row "),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createCommentVNode("begin::Input group"),
                _createElementVNode("div", _hoisted_21, [
                  _createCommentVNode("begin::Label"),
                  _cache[15] || (_cache[15] = _createElementVNode("label", { class: "fs-5 fw-bold mb-2 ms-2" }, "Product Count", -1 /* HOISTED */)),
                  _createVNode(_component_Field, {
                    type: "text",
                    class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                    placeholder: "Product Count",
                    name: "product_count",
                    modelValue: _ctx.nbiotData.product_count,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.nbiotData.product_count) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ])
              ]),
              (_ctx.picked == 'v2')
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createCommentVNode("begin::Input group"),
                    _createElementVNode("div", _hoisted_23, [
                      _createCommentVNode("begin::Label"),
                      _cache[16] || (_cache[16] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Device Serial ID", -1 /* HOISTED */)),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "bg-light rounded-2 form-control form-control-solid border border-primary",
                        placeholder: "Device Serial ID",
                        name: "serial_number",
                        modelValue: _ctx.nbiotData.serial_number,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.nbiotData.serial_number) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(_component_ErrorMessage, { name: "serial_number" })
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.picked == 'v1')
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _cache[17] || (_cache[17] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Device Serial Number", -1 /* HOISTED */)),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid border border-primary",
                        rows: "3",
                        name: "serial_number",
                        placeholder: "Serial Number",
                        modelValue: _ctx.nbiotData.serial_number,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.nbiotData.serial_number) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createVNode(_component_ErrorMessage, { name: "serial_number" })
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.picked == 'v2')
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _cache[18] || (_cache[18] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2 ms-2" }, "Device UID", -1 /* HOISTED */)),
                      _createVNode(_component_Field, {
                        type: "text",
                        class: "form-control form-control-solid border border-primary",
                        rows: "3",
                        name: "unique_random_number",
                        placeholder: "Device UID",
                        modelValue: _ctx.nbiotData.unique_random_number,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.nbiotData.unique_random_number) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("div", _hoisted_33, [
                          _createVNode(_component_ErrorMessage, { name: "unique_random_number" })
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"d-flex flex-column mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 mb-2\">Firmware Version</label>\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"form-control form-control-solid border border-primary\"\r\n                  rows=\"3\"\r\n                  name=\"firmware_version\"\r\n                  placeholder=\"Firmware Version\"\r\n                  v-model=\"nbiotData.firmware_version\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"firmware_version\" />\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </div> ")
            ]),
            _createCommentVNode(" Second row end "),
            _createCommentVNode(" Third Row "),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "row" }, [
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 mb-2\">Sensor Type </label>\r\n                <Field\r\n                  name=\"sensor\"\r\n                  data-control=\"select2\"\r\n                  data-hide-search=\"true\"\r\n                  placeholder=\"Select sensor type\"\r\n                  data-placeholder=\"Sensor Type\"\r\n                  class=\"form-select border-primary form-select-solid border border-primary\"\r\n                  as=\"select\"\r\n                  v-model=\"nbiotData.sensor\"\r\n                >\r\n                  <option value=\"JSN 2.0\">JSN 2.0</option>\r\n                  <option value=\"JSN 3.0\">JSN 3.0</option>\r\n                  <option value=\"TOF\">TOF</option>\r\n                </Field>\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"sensor\" />\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </div> "),
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"d-flex flex-column mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 ms-2\">Casing Batch Number</label>\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"form-control form-control-solid border border-primary\"\r\n                  rows=\"3\"\r\n                  name=\"casing_batch_number\"\r\n                  placeholder=\"Casing Batch Number\"\r\n                  v-model=\"nbiotData.casing_batch_number\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"casing_batch_number\" />\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </div> "),
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"d-flex flex-column mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 ms-2\">Antenna</label>\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"form-control form-control-solid border border-primary\"\r\n                  rows=\"3\"\r\n                  name=\"antenna\"\r\n                  placeholder=\"antenna\"\r\n                  v-model=\"nbiotData.antenna\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"antenna\" />\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </div> ")
            ], -1 /* HOISTED */)),
            _createCommentVNode(" Third Row end "),
            _createCommentVNode(" Four Row  "),
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "row" }, [
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 ms-2\">Battery</label>\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"bg-light rounded-2 form-control form-control-solid border border-primary\"\r\n                  placeholder=\"batter\"\r\n                  name=\"batter\"\r\n                  v-model=\"nbiotData.batter\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"batter\" />\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </div> "),
              _createElementVNode("div", { class: "d-flex flex-column mb-5 fv-row" }, [
                _createCommentVNode("  <label class=\"fs-5 fw-bold mb-2 ms-2\">Gasket</label>\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"form-control form-control-solid border border-primary\"\r\n                  rows=\"3\"\r\n                  name=\"gasket\"\r\n                  placeholder=\"Gasket\"\r\n                  v-model=\"nbiotData.gasket\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"gasket\" />\r\n                  </div>\r\n                </div>\r\n              </div> ")
              ]),
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"d-flex flex-column mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 ms-2\">Screws</label>\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"form-control form-control-solid border border-primary\"\r\n                  rows=\"3\"\r\n                  name=\"screws\"\r\n                  placeholder=\"Screws\"\r\n                  v-model=\"nbiotData.screws\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"screws\" />\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </div> ")
            ], -1 /* HOISTED */)),
            _createCommentVNode(" Four Row End "),
            _createCommentVNode(" Five Row  "),
            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "row" }, [
              _createElementVNode("div", { class: "col-md-4" }, [
                _createCommentVNode(" <div class=\"mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold ms-2\" style=\"margin-bottom: 32px;\">Conformal Coating</label>\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"bg-light rounded-2 form-control form-control-solid border border-primary\"\r\n                  placeholder=\"Conforma coating\"\r\n                  name=\"conformal_coating\"\r\n                  v-model=\"nbiotData.conformal_coating\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"conformal_coating\" />\r\n                  </div>\r\n                </div>\r\n              </div> ")
              ]),
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"d-flex flex-column mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 ms-2\"\r\n                  >Silicon Between Lid and Housing</label\r\n                >\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"form-control form-control-solid border border-primary\"\r\n                  rows=\"3\"  \r\n                  name=\"silicon_between_lid_and_housing\"\r\n                  placeholder=\"Silicon Between Lid and Housing\"\r\n                  v-model=\"nbiotData.silicon_between_lid_and_housing\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"silicon_between_lid_and_housing\" />\r\n                  </div>\r\n                </div>\r\n              </div> \r\n            </div>"),
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"d-flex flex-column mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 ms-2\"\r\n                  >Payload Specification Document Version</label\r\n                >\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"form-control form-control-solid border border-primary\"\r\n                  rows=\"3\"\r\n                  name=\"payload_specification_document_version\"\r\n                  placeholder=\"pyload\"\r\n                  v-model=\"nbiotData.payload_specification_document_version\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage\r\n                      name=\"payload_specification_document_version\"\r\n                    />\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </div> ")
            ], -1 /* HOISTED */)),
            _createCommentVNode(" Five Row End "),
            _createCommentVNode(" Six Row  "),
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "row" }, [
              _createCommentVNode(" <div class=\"col-md-4\">\r\n              <div class=\"mb-5 fv-row\">\r\n                <label class=\"fs-5 fw-bold mb-2 ms-2\">Sealant On Tunel</label>\r\n                <Field\r\n                  type=\"text\"\r\n                  class=\"bg-light rounded-2 form-control form-control-solid border border-primary\"\r\n                  placeholder=\"Sealant On Tunel\"\r\n                  name=\"sealant_on_tunnel\"\r\n                  v-model=\"nbiotData.sealant_on_tunnel\"\r\n                />\r\n                <div class=\"fv-plugins-message-container\">\r\n                  <div class=\"fv-help-block\">\r\n                    <ErrorMessage name=\"sealant_on_tunnel\" />\r\n                  </div>\r\n                </div>\r\n              </div>\r\n            </div> ")
            ], -1 /* HOISTED */)),
            _createCommentVNode(" Six Row End ")
          ]),
          _createCommentVNode("end::Scroll")
        ]),
        _createCommentVNode("end::Modal body"),
        _createCommentVNode("begin::Modal footer"),
        _createElementVNode("div", _hoisted_34, [
          _createCommentVNode("begin::Button"),
          _createElementVNode("button", _hoisted_35, _cache[23] || (_cache[23] = [
            _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
            _createElementVNode("span", { class: "indicator-progress" }, [
              _createTextVNode(" Please wait... "),
              _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
            ], -1 /* HOISTED */)
          ]), 512 /* NEED_PATCH */),
          _createCommentVNode("end::Button")
        ]),
        _createCommentVNode("end::Modal footer")
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onSubmit", "validation-schema"]),
    _createCommentVNode("end::Form")
  ]))
}